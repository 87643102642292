import { Col, Container, Row } from "react-bootstrap";
// import logo from "./Logo.png";
import name from "./cricektclash.png";

export const Bottom = () => {
	return (
		<section
			style={{
				maxWidth: "100vw",
				height: "20vw",
				background: "#000000",
				overflowX: "hidden",
			}}
			id="bottom"
		>
			<Container style={{ position: "relative", zIndex: 100, maxWidth: "100vw", height: "100%" }}>
				<Row style={{ height: "100%", alignItems: "center", fontFamily: "Kanit" }}>
					<Col xs={{ span: 4 }} style={{ display: "flex", justifyContent: "center" }}>
						<div>
							{/* <img
								style={{
									height: "10vh",
								}}
								src={logo}
							/> */}
							<img
								style={{
									height: "8vh",
								}}
								src={name}
							/>
						</div>
					</Col>
					<Col
						style={{
							display: "flex",
							flexDirection: "row",
							fontSize: "0.9rem",
							// fontFamily: "Kanit",
							color: "#FFFFFF",
						}}
					>
						<div
							className="grid-container"
							style={{
								display: "grid",
								gridTemplateColumns: "auto auto auto",
								marginTop: "6vh",
								columnGap: "7vw",
								textAlign: "center",
							}}
						>
							<div className="grid-item">
								<a href="#home" style={{ textDecoration: "none", color: "white" }}>
									HOME
								</a>
							</div>
							<div className="grid-item">
								<a href="#gameplay" style={{ textDecoration: "none", color: "white" }}>
									GAMEPLAY
								</a>
							</div>
							<div className="grid-item">
								<a href="#roadmap" style={{ textDecoration: "none", color: "white" }}>
									ROADMAP
								</a>
							</div>
							{/* <div className="grid-item" /> */}
							<div className="grid-item">
								<a href="#faq" style={{ textDecoration: "none", color: "white" }}>
									FAQ
								</a>
							</div>
							<div className="grid-item">
								<a href="#partners" style={{ textDecoration: "none", color: "white" }}>
									PARTNERS
								</a>
							</div>
						</div>
						<div
							style={{
								width: "0",
								marginLeft: "10vw",
								height: "15vh",
								borderLeft: "1px solid grey",
							}}
						></div>
					</Col>
					<Col style={{ marginLeft: "5vw", marginTop: "6vh" }}>
						<div style={{ alignItems: "center", marginBottom: "2vh" }}>
							<div
								style={{
									fontSize: "0.9rem",
									// fontSize: "1.25rem",
									// fontFamily: "Kanit",
									color: "#FFFFFF",
								}}
							>
								INQUIRY
							</div>
						</div>
						<div style={{ position: "relative", marginBottom: "2vh" }}>
							<div
								style={{
									fontSize: "0.9rem",
									// fontSize: "1.25rem",
									// fontFamily: "Kanit",
									color: "#FFFFFF",
								}}
							>
								GAME SUPPORT
							</div>
						</div>
						<div style={{ position: "relative", marginBottom: "2vh" }}>
							<div
								style={{
									fontSize: "0.9rem",
									// fontSize: "1.25rem",
									// fontFamily: "Kanit",
									color: "#FFFFFF",
								}}
							>
								<a href="https://cricinshots.com/privacypolicy/" target="_blank" style={{ textDecoration: "none", color: "white" }}>
									PRIVACY POLICY
								</a>
							</div>
						</div>
						<div style={{ position: "relative", marginBottom: "2vh" }}>
							<div
								style={{
									fontSize: "0.9rem",
									// fontSize: "1.25rem",
									// fontFamily: "Kanit",
									color: "#FFFFFF",
								}}
							>
								<a href="https://cricinshots.com/terms/" target="_blank" style={{ textDecoration: "none", color: "white" }}>
									TERMS
								</a>
							</div>
						</div>
					</Col>
					{/* <Row>
						logos
					</Row> */}
				</Row>
			</Container>
		</section>
	);
};
