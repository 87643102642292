import React, { useState } from "react";
import { SuccessRegisterModal } from "../SuccessRegisterModal";
const IntroPageMobile = () => {
	// email state
	const [email, setEmail] = useState("");
	const [isMailSet, setIsMailSet] = useState(false);
	const [show, setShow] = useState(false);
	// On Register
	const onRegister = (e: React.FormEvent<HTMLInputElement>) => {
		if (!isMailSet) {
			ValidateEmail(email);
			e.preventDefault();
			console.log("Email is", email);
		} else {
			alert("You have already pre-registered!");
			e.preventDefault();
		}
	};

	const sendMail = (mail: string) => {
		const data = { email: mail };
		fetch("https://cricinshots.in/cc/beta/emailAPI/adminEmailGroups.php", {
			method: "POST",
			body: JSON.stringify(data),
		}).then(async (response) => {
			if (response.ok) {
				console.log("Email successfully submitted");
				setShow(true);
			} else {
				let res = (await response.json()) as any;
				alert(res.message);
				console.error(res);
			}
		});
	};

	const ValidateEmail = (mail1: string) => {
		if (mail1) {
			let mail = mail1.trim();
			sendMail(mail);
		}
		return true;
	};
	return (
		<div className="section1 section1M">
			<video src={process.env.PUBLIC_URL + "/video/firstVideo.mp4"} autoPlay loop muted></video>
			<div className="header-title header-titleM" style={{marginLeft:"20px"}}>
				<img src={process.env.PUBLIC_URL + "/images/logo.png"} />
				<span>Wega Labs</span>
			</div>
			{/*	<img
				src={process.env.PUBLIC_URL + "images/ellipseM.png"}
				className="ellipseShape ellipseShapeM"
				alt="Ellipse Shape"
			/>*/}
			<div className="section1-container m">
				<header style={{marginBottom:"-80px"}}>
					<img src={process.env.PUBLIC_URL + "/images/cricektclash.png"} style={{width:"300px",height:"55px" ,marginLeft:"15px"}}/>
				</header>
				<div className="section1-container-info m" style={{marginBottom:"-70px", marginLeft:"15px"}}>
				An Epic Cricket Strategy game - build your stadium, collect cards, manage your team and win!
				</div>

				<a href='https://play.google.com/store/apps/details?id=com.wegalabs.cricinshots&utm_source=cricinshots.com&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' style={{ display: "flex", flexDirection: "column", justifyContent: "center",marginLeft:"15px", marginBottom:"-30px"}} target='_blank'>
					<img id="get-it-on-google-play-button-m" alt='Get it on Google Play' src={process.env.PUBLIC_URL + "/images/google-play-badge.png"}/>
				</a>
				<a href='https://testflight.apple.com/join/iokI3aU8' style={{ display: "flex", flexDirection: "column", justifyContent: "center",marginLeft:"15px" }} target='_blank'>
					<img id="get-it-on-testflight-m" src={process.env.PUBLIC_URL + "/images/testflight.png"}/>
				</a>

				{/* <div className="section1-container-alert m">PRE-REGISTER NOW!</div>
				<form className="section1-container-form m">
					<input
						type="text"
						placeholder="E-mail"
						className="section1-container-form-text m"
						onChange={(e) => setEmail(e.target.value)}
					/>
					<input
						type="submit"
						className="section1-container-form-submit m"
						value="REGISTER"
						onClick={(e) => {
							onRegister(e);
						}}
					/>
				</form>
				{isMailSet ? <div className="mail-set m">Thanks For Registering!</div> : <></>} */}
				{/*<div className="section1-container-countdown m">
					<div>WE ARE COMING</div>
					<div className="section1-container-countdown-timers m">
						<span className="section1-container-countdown-timer1"></span>
						<span className="section1-container-countdown-timer2"></span>
						<div className="section1-container-countdown-colon m">
							<img src={process.env.PUBLIC_URL + "/images/colon.png"} />
						</div>
						<span className="section1-container-countdown-timer3"></span>
						<span className="section1-container-countdown-timer4"></span>
					</div>
				</div>*/}
			</div>
			<SuccessRegisterModal show={show} setShow={setShow} />
		</div>
	);
};

export default IntroPageMobile;
