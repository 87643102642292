import "./partner.css"

const PartnerMobile = () => {
	return (
		<div className="partner-container m grad" style={{ minHeight: "100vh", maxWidth: "100vw" }}>
			<h1 style={{ fontFamily: "Heading", fontSize: "3.5rem", marginBottom:"-55px"}}>PARTNERS</h1>
			<section className="partner-img-container1 m">
				<img src={process.env.PUBLIC_URL + "/images/belief.png"} alt="" />
				<img src={process.env.PUBLIC_URL + "/images/atal.png"} alt="" />
				<img src={process.env.PUBLIC_URL + "/images/antler.png"} alt="" />
				<img src={process.env.PUBLIC_URL + "/images/sifs.png"} style={{width:"45%"}} alt="" />
			</section>
			{/* this is not dynalic please merge sections if you want to add more */}
			<section className="partner-img-container1 " style={{alignContent:"center", marginBottom:"-200px"}}>
				<img src={process.env.PUBLIC_URL + "/images/tegro.png"} style={{ width:"50%", marginTop:"-250px"}} alt="" />
			</section>
				
			{/* <br />
			<br /> */}
			<div className="partner-container-text m" style={{ fontFamily: "Heading", fontSize: "3.5rem", marginBottom:"-55px"}}>As seen in</div>
			<section className="partner-img-container3 m">
				<img src={process.env.PUBLIC_URL + "/images/aim.png"} alt="" />
				<img src={process.env.PUBLIC_URL + "/images/inc42.png"} alt="" />
			</section>

			<section className="partner-img-container1 " style={{alignContent:"center", marginBottom:"-200px"}}>
				<img src={process.env.PUBLIC_URL + "/images/forbess.png"} style={{ width:"50%", marginTop:"-250px"}} alt="" />
			</section>
		</div>
	);
};

export default PartnerMobile;
