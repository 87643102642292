import Header from "./components/headerComponents/Header";
import ContentContainer from "./components/bodyComponents/ContentContainer";
import Footer from "./components/footerComponents/Footer";
import useBreakpoint from "use-breakpoint";
import { useEffect } from "react";
let BREAKPOINTS = {
	mobile: 0,
	desktop: 1000,
};
function App() {
	const { breakpoint } = useBreakpoint(BREAKPOINTS);
	useEffect(() => {
		fetch("https://go.cricinshots.com/page_load.php").catch((err) => {
			console.error(err);
		});
	}, []);
	return (
		<div className="App">
			{breakpoint === "mobile" ? <></> : <Header />}
			<img src="./images/sideScroller.png" alt="" className="side-scroller-img" />
			<span className="side-scroller">
				<a href="https://cricinshots.com/1club" target="_blank">
					<img src="./images/discord.png" alt="" />
				</a>
				<a href="https://twitter.com/cricinshots" target="_blank">
					<img src="./images/twitter.png" alt="" />
				</a>
				<a href="https://instagram.com/cricinshots" target="_blank">
					<img src="./images/instagram.png" alt="" />
				</a>
				<a href="https://facebook.com/cricinshots" target="_blank">
					<img src="./images/facebook.png" alt="" />
				</a>
				<a href="https://t.me/+kBLSiQQrSDtlZmY9" target="_blank">
					<img src="./images/telegram.png" alt="" />
				</a>
				<a href="https://www.linkedin.com/company/cricinshotsshowcase/" target="_blank">
					<img src="./images/linkedin.png" alt="" />
				</a>
			</span>
			<ContentContainer />
			<Footer />
		</div>
	);
}

export default App;
