import "./gameplay.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import { gameplay1, gameplay2, gameplay3 } from "../../text";

export const GameplayLandscape = () => {
	return (
		<section
			style={{
				maxWidth: "100vw",
				minHeight: "100vh",
				// background: "#1d1d34",
				background: "#0B0B1E",
				// overflowX: "hidden",
			}}
			id="gameplay"
		>
			<Container className="gameplay-container">
				<Row>
					<Col className="image-hero">
						<div style={{height:"100%", width:"100%"}} >
							<img src={process.env.PUBLIC_URL + "/images/gameplay.png"} style={{width:"100%",height:"100%", objectFit:"cover",borderTop:"100px"}} />
						</div>
					</Col>
					<Col
						style={{
							position: "relative",
							maxWidth: "46vw",
							// maxHeight: "35vh",
							// top: "-20vh",
							// right: "calc(20rem + -18.5vw)",
						}}
						className="gameplay-col"
					>
						<Row style={{ maxHeight: "1px", textAlign: "center" }}>
							<div
								style={{
									fontFamily: "Heading",
									alignContent: "center",
									// height: "20vh",
									// color: "#E3E3E3",
									color: "#f0f0f0",
									fontSize: "80px",
									// maxHeight: "10vh",
								}}
							>
								GAMEPLAY
							</div>
						</Row>
						<Row
							style={{
								fontFamily: "Kanit",
								// left: "calc(47vw)",
								textAlign: "center",
								fontSize: "1.35vw",
								marginTop: "1vh",
								marginBottom: "3.5vh",
								// color: "rgb(227 227 227 / 52%)",
								color: "#f0f0f0"
							}}
						>
							{gameplay3}
						</Row>
					</Col>
				</Row>
			</Container>
			{/* <Container style={{ position: "relative", top: "40vh" }}>
				<Row>
					<Col>
						<div style={{ width: "100%", position: "relative" }}>
							<img src={process.env.PUBLIC_URL + "/images/gameplay.png"} style={{ height: "400px", width: "550px", marginTop: "-120px" }} />
						</div>
					</Col>
					<Col
						style={{
							position: "relative",
							maxWidth: "46vw",
							maxHeight: "35vh",
							// top: "-20vh",
							// right: "calc(20rem + -18.5vw)",
						}}
					>
						<Row style={{ maxHeight: "10vh", textAlign: "center" }}>
							<div
								style={{
									fontFamily: "Heading",
									alignContent: "center",
									// height: "20vh",
									// color: "#E3E3E3",
									color: "#f0f0f0",
									fontSize: "80px",
									maxHeight: "10vh",
								}}
							>
								GAMEPLAY
							</div>
						</Row>
						<Row
							style={{
								fontFamily: "Kanit",
								// left: "calc(47vw)",
								textAlign: "center",
								fontSize: "1.35vw",
								marginTop: "7vh",
								marginBottom: "3.5vh",
								// color: "rgb(227 227 227 / 52%)",
								color: "#f0f0f0"
							}}
						>
							{gameplay1}
						</Row>
					</Col>
				</Row>
			</Container> */}
		</section>
	);
};
