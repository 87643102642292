import PropTypes from "prop-types";

const Anchor = (props: { anchorText: string; anchorClass?: string; anchorLink?: string }) => {
	return (
		<>
			<a
				href={props.anchorLink ? props.anchorLink : "#" + props.anchorText.toLowerCase()}
				className={props.anchorClass}
			>
				{props.anchorText}
			</a>
		</>
	);
};

Anchor.defaultProps = {
	anchorClass: "",
};

export default Anchor;
