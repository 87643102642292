import React from "react";

const Footer = () => {
	return (
		<footer style={{ fontFamily: "Kanit" }}>
			<span>Copyright© 2021 |</span>
			<span> Made with ❤️ in India for the World</span>
		</footer>
	);
};

export default Footer;
