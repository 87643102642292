import "./partner.css"

const Partner = () => {
	return (
		<div className="partner-container grad" style={{ paddingTop: "5vh", maxWidth: "100vw", minHeight: "100vh"
		, paddingBottom: "110vh" 

		}}>
			{/* <span className="partner-imgs">
				<img
					src={process.env.PUBLIC_URL + "/images/left.png"}
					alt=""
					className="partner-left-img"
				/>
				<img
					src={process.env.PUBLIC_URL + "/images/right.png"}
					alt=""
					className="partner-right-img"
				/>
			</span> */}
			{/* {Gradient} */}
			<h1 style={{ fontFamily: "Heading", fontSize: "80px", marginTop: "10px",marginBottom: "50px" }}>PARTNERS</h1>

			<section className="partner-img-container1" style={{ marginTop: "-7vh", alignContent:"center" }}>
				<img src={process.env.PUBLIC_URL + "/images/antler.png"} alt="" style={{ height: "200px", width: "200px" }} />
				<img src={process.env.PUBLIC_URL + "/images/atal.png"} alt="" style={{ height: "200px", width: "200px" }} />
			{/* </section>
			<section className="partner-img-container2"> */}
				<img src={process.env.PUBLIC_URL + "/images/belief.png"} alt="" style={{ height: "200px", width: "200px" }} />
				<img src={process.env.PUBLIC_URL + "/images/sifs.png"} alt="" style={{ height: "200px", width: "200px", padding: "30px" }} />
				<img src={process.env.PUBLIC_URL + "/images/tegro.png"} alt="" style={{ height: "200px", width: "200px" }} />
			</section>
			<section className="partner-img-container2">
				<img src={process.env.PUBLIC_URL + "/images/innovator_program.png"} alt="" style={{ height: "200px", width: "200px" }} />
				<img src={process.env.PUBLIC_URL + "/images/Web3InPune.png"} alt="" style={{ height: "200px", width: "200px" }} />
				<img src={process.env.PUBLIC_URL + "/images/BuidlersTribe.png"} alt="" style={{ height: "200px", width: "200px", padding: "20px" }} />
			</section>

			{/* <span className="partner-container-text">As seen in</span> */}
			<h1 style={{ fontFamily: "Heading", fontSize: "80px", marginTop: "20vh",marginBottom: "40px" }}>AS SEEN IN</h1>

			<section className="partner-img-container3" style={{ marginTop: "-10vh", marginBottom:"20vh" }}>
				<img src={process.env.PUBLIC_URL + "/images/forbess.png"} alt="" style={{ height: "200px", width: "200px", padding: "20px" }} />
				<img src={process.env.PUBLIC_URL + "/images/aim.png"} alt="" style={{ height: "200px", width: "200px", padding: "20px" }} />
				<img src={process.env.PUBLIC_URL + "/images/inc42.png"} alt="" style={{ height: "200px", width: "200px", padding: "20px" }} />
			</section>
			
		</div>
	);
};

export default Partner;
