import IntroPageLandscape from "./IntroPageLandscape";
import { RoadmapLandscape } from "./Roadmap/RoadmapLandscape";
import { RoadmapPhone } from "./Roadmap/RoadmapPhone";
import useBreakpoint from "use-breakpoint";
import IntroPageMobile from "./IntroPageMobile";
import { FAQ } from "./FAQ/FAQ";
import Partner from "./Partner/Partner";
import PartnerMobile from "./Partner/PartnerMobile";
import TournamentsLandscape from "./Tournaments/TournamentsLandscape";
import TournamentsPhone from "./Tournaments/TournamentsPhone";
import { GameplayPhone } from "./Gameplay/GameplayPhone";
import { Bottom } from "./Bottom/Bottom";
import { BottomPhone } from "./Bottom/BottomPhone";
import { GameplayLandscape } from "./Gameplay/GameplayLandscape";
import TeaserLandscape from "./Teaser/TeaserLandscape";
import TeaserPhone from "./Teaser/TeaserPhone";
import { InTheNewsLandscape } from "./InTheNews/InTheNewsLandscape";
import { InTheNewsMobile } from "./InTheNews/InTheNewsMobile";

let BREAKPOINTS = {
	mobile: 0,
	desktop: 1000,
};
const ContentContainer = () => {
	const { breakpoint } = useBreakpoint(BREAKPOINTS);
	return (
		<div className="content-container">
			<div id="home">{breakpoint === "mobile" ? <IntroPageMobile /> : <IntroPageLandscape />}</div>
			{/* <Section2 /> */}
			{/*<div id="teaser">{breakpoint === "mobile" ? <TeaserPhone /> : <TeaserLandscape />}</div>*/}
			<div id="gameplay">{breakpoint === "mobile" ? <GameplayPhone /> : <GameplayLandscape />}</div>
			{/* <div id="roadmap">{breakpoint === "mobile" ? <RoadmapPhone /> : <RoadmapLandscape />}</div> */}
			{/* <div id="tournaments"><TournamentsLandscape /></div> */}
			{/*<div id="tournaments">{breakpoint === "mobile" ? <TournamentsPhone /> : <TournamentsLandscape />}</div>*/}
			<div id="faq">
				<FAQ />
			</div>
			<div id="partners">{breakpoint === "mobile" ? <PartnerMobile /> : <Partner />}</div>
			{/* <div id="inTheNews">{breakpoint === "mobile" ? <InTheNewsMobile /> : <InTheNewsLandscape />}</div> */}
			{breakpoint === "mobile" ? <BottomPhone /> : <Bottom />}
		</div>
	);
};

export default ContentContainer;
