import { useState } from "react";
import { SuccessRegisterModal } from "../SuccessRegisterModal";

const IntroPageLandscape = () => {
	// email state
	const [email, setEmail] = useState("");
	const [isMailSet, setIsMailSet] = useState(false);
	const [show, setShow] = useState(false);
	// On Register
	const onRegister = (e: React.FormEvent<HTMLInputElement>) => {
		if (!isMailSet) {
			ValidateEmail(email);
			e.preventDefault();
			console.log("Email is", email);
		} else {
			alert("You have already pre-registered!");
			e.preventDefault();
		}
	};

	const sendMail = (mail: string) => {
		const data = { email: mail };
		fetch("https://cricinshots.in/cc/beta/emailAPI/adminEmailGroups.php", {
			method: "POST",
			body: JSON.stringify(data),
		}).then(async (response) => {
			if (response.ok) {
				console.log("Email successfully submitted");
				setShow(true);
			} else {
				let res = (await response.json()) as any;
				alert(res.message);
				console.error(res);
			}
		});
	};

	const ValidateEmail = (mail1: string) => {
		if (mail1) {
			let mail = mail1.trim();
			sendMail(mail);
		}
		return true;
	};
	return (
		<div className="section1">
			{/* <img
				style={{
					position: "absolute",
					left: "-10vw",
					top: "-10vw",
					width: "40vw",
					zIndex: -1,
					transform: "rotateZ(113deg)",
				}}
				src={process.env.PUBLIC_URL + "images/TopLeftDot.png"}
			/> */}
			<video src={process.env.PUBLIC_URL + "/video/firstVideo.mp4"} autoPlay loop muted></video>
			{/* <div className="vid">
				<p style={{ height: "100vh", width: "100vw" }}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Totam saepe optio dolorum a, eos corrupti cumque fugiat sint similique quibusdam exercitationem maxime inventore necessitatibus officia fuga nam. Accusantium, cupiditate consequuntur!</p>
			</div> */}
			<div className="header-title">
				<img src={process.env.PUBLIC_URL + "/images/logo.png"} />
				<span>Wega Labs</span>
			</div>

			<div className="section1-container">
				<header>
					<img src={process.env.PUBLIC_URL + "/images/cricektclash.png"} style={{width:"400px",height:"75px"}}/>
				</header>
				<div className="section1-container-info" style={{marginBottom:"15px"}}>
				An Epic Cricket Strategy game - build your stadium, collect cards, manage your team and win!
				</div>

				{/* <div className="d-flex inline-block"> */}
					{/* <a href='https://play.google.com/store/apps/details?id=com.wegalabs.cricinshots&utm_source=cricinshots.com&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank'>
						<img id="get-it-on-google-play-button" alt='Get it on Google Play' src={process.env.PUBLIC_URL + "/images/google-play-badge.png"}/>
					</a>
					<a href='https://testflight.apple.com/join/iokI3aU8' style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} target='_blank'>
						<img id="get-it-on-testflight" alt='Download on the App Store' src={process.env.PUBLIC_URL + "/images/testflight.png"}/>
					</a> */}
				{/* </div> */}

				<div className="row">
					<div className="col">
						<a href='https://play.google.com/store/apps/details?id=com.wegalabs.cricinshots&utm_source=cricinshots.com&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} target='_blank'>
							<img id="get-it-on-google-play-button" alt='Get it on Google Play' src={process.env.PUBLIC_URL + "/images/google-play-badge.png"}/>
						</a>
					</div>
					<div className="col">
						<a href='https://testflight.apple.com/join/iokI3aU8' style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} target='_blank'>
							<img id="get-it-on-testflight" alt='Download on the App Store' src={process.env.PUBLIC_URL + "/images/testflight.png"}/>
						</a>
					</div>
				</div>

				{/* <div className="section1-container-alert">PRE-REGISTER NOW!</div>
				<form className="section1-container-form">
					<input
						type="text"
						placeholder="E-mail"
						className="section1-container-form-text"
						onChange={(e) => setEmail(e.target.value)}
					/>
					<input
						type="submit"
						className="section1-container-form-submit"
						value="REGISTER"
						onClick={(e) => {
							onRegister(e);
						}}
					/>
				</form>
				{isMailSet ? <div className="mail-set">Thanks For Registering!</div> : <></>} */}
				{/*<div className="section1-container-countdown">
					<div>WE ARE COMING</div>
					<div className="section1-container-countdown-timers">
						<span className="section1-container-countdown-timer1"></span>
						<span className="section1-container-countdown-timer2"></span>
						<div className="section1-container-countdown-colon">
							<img src={process.env.PUBLIC_URL + "/images/colon.png"} />
						</div>
						<span className="section1-container-countdown-timer3"></span>
						<span className="section1-container-countdown-timer4"></span>
					</div>
				</div>*/}
			</div>
			{/*<img src={process.env.PUBLIC_URL + "/images/ellipse.png"} className="ellipseShape" alt="Ellipse Shape" />*/}
			{/* <span className="ellipseShape"></span> */}
			<SuccessRegisterModal show={show} setShow={setShow} />
		</div>
	);
};

export default IntroPageLandscape;
