import Anchor from "./Anchor";

const Anchors = () => {
	// const style = {
	//   '@media (max-width: 800px)' : {
	//     display: 'none'
	//   }
	// }
	return (
		<span className="anchors">
			<Anchor anchorText={"HOME"} anchorClass={"firstChild"} />
			<Anchor anchorText={"GAMEPLAY"} />
			<Anchor anchorText={"ROADMAP"} />
			<Anchor anchorText={"FAQ"} />
			{/*<Anchor anchorText={"TEAM"} />*/}
			<Anchor anchorText={"PARTNERS"} />
			{/* <Anchor anchorText={"NEWS"} /> */}
			<Anchor anchorText={"BLOGS"} anchorLink={"https://blog.cricinshots.com"} anchorClass={"lastSecondChild"} />
			<Anchor anchorText={"DOWNLOAD"} anchorClass={"lastChild"} anchorLink={"https://cricinshots.com/get"} />
		</span>
	);
};

export default Anchors;
