import { Accordion, Col, Container, Row } from "react-bootstrap";
import "./faq.css";
import ballfaq from "./ball-faq.png";
import stump from "./stump.png";

let faq = [
	{
		q: "What happened to Cricinshots? Is Cricket Clash a different game?",
		a: "Yes, Cricket Clash is a new game we are currently developing. We have shelved Cricinshots for the time being to focus on creating an even better gaming experience with Cricket Clash.",
	},
	{
		q: "Is Cricket Clash a card game or a sports game?",
		a: "Cricket Clash is a unique blend of both! It combines the strategic elements of a card game with the excitement and skill of a cricket game. You'll need to collect and play the right cards, but also master the timing of your shots to score big runs.",
	},
	{
		q: "Which devices and platforms is Cricket Clash available on?",
		a: "It is available on Android phones, iOS phones, Macbooks and ipads",
	},
	// {
	// 	q: "What is Cricket Clash?",
	// 	a: "Cricket Clash is an immersive PvP cricket strategy game where players step into the role of a team manager, tasked with assembling a competitive team, making trades, and directing their team to success on the pitch.",
	// },
	// {
	// 	q: "Is Cricket Clash Free to Play?",
	// 	a: "Cricket Clash is completely free to play with optional in app purchases which you as a user might opt to buy",
	// },
	// {
	// 	q: "What is P2E / Play-to-Earn / Play-and-Earn?",
	// 	a: "Play to Earn games / P2E games are new kinds of video games that bring together two things everyone likes the most - entertainment and money. Players play the games and participate in virtual activities to earn crypto token rewards, which they can use in-game or trade on an open market. This is a significant advancement in the gaming industry, as these types of games offer users a return of investment for their time and money that they spend on the game.",
	// },
	// {
	// 	q: "What makes Cricket Clash stand out?",
	// 	a: "The gameplay is built around a deck of player, tactics, and equipment cards. The strategic deployment of this deck can significantly impact the trajectory of the match, ensuring a suspense-filled and engaging experience.",
	// },
	// {
	// 	q: "Is it safe to play such P2E games?",
	// 	a: "It is safe to participate in P2E gaming as long as you make sure you trust the developers, do not share your wallet keys with anyone, and stay vigilant in general.We also advise you to conduct your own study and educate yourself on how to utilize crypto safely, securely, and responsibly. Cricinshots is striving to provide you a safe platform and we are backed by marquee investors and DAOs",
	// },
];
export const FAQ = () => {
	return (
		<section id="faq" style={{ maxWidth: "100vw", minHeight: "100vh", background: "#0B0B1E", marginTop: "-1px" }}>
			{/* <img src={ballfaq} style={{ position: "absolute", left: "-10px", width: "15vw", zIndex: 0 }} /> */}
			{/* <img src={stump} style={{ position: "absolute", right: "0px", marginTop: "100px", width: "15vw", zIndex: 0 }} /> */}
			<Container style={{ fontFamily: "Russo One", paddingTop: "10vh" }}>
				<Row>
					<Col style={{ textAlign: "center" }}>
						{/* <img src={ballfaq} style={{ height: "80px", width: "80px", marginTop: "-10px", marginRight: "20px" }} /> */}
						<h1 style={{ fontSize: "80px", fontFamily: "Heading", color: "white", textAlign: "center", display: "inline-block" }}>FAQ</h1>
						<img src={stump} style={{ height: "100px", width: "110px", marginTop: "-30px" }} />
					</Col>
				</Row>
				{faq.map((question, index) => (
					<Row className="mt-3" key={index}>
						<Col>
							<Accordion className="acc" style={{ marginBottom: "10px" }}>
								<Accordion.Header className="radius" style={{ color: "#000000" }}>{question.q}</Accordion.Header>	{/* question */}
								<Accordion.Body		// answer
									className="radius-body"
									style={{
										background: "#272727",
										color: "white",
										fontSize: "16px",
										fontFamily: "Kanit",
										// fontWeight: "bold",
										// position: "relative",
										// marginBottom: "10px",
										zIndex: 0,
										borderRadius: "0  0 25px 25px",
										margin: "-25px -25px 25px -25px",
										paddingTop: "50px",
										transition: "0s"
									}}
								>
									<span style={{
										// position: "relative",
										zIndex: 10
									}}>{question.a}</span>
								</Accordion.Body>
							</Accordion>
						</Col>
					</Row>
				))}
			</Container>
		</section>
	);
};
